import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-services",
    templateUrl: "./services.component.html",
    styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {}

    goToContact() {
        this.router.navigateByUrl("/contact");
    }
    goToDetails(serviceName: any) {
        this.router.navigateByUrl(`/service-details/${serviceName}`);
    }
}
