import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
declare let UIkit: any;

interface MenuItem {
    label: string;
    route: string;
}
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    location: any;
    logo: any;
    navClass: any;
    menuItems: MenuItem[] = [
        { label: "Home", route: "/" },
        { label: "About", route: "/about" },
        { label: "Services", route: "/services" },
        { label: "Hire Developer", route: "/hire-me" },
        { label: "Contact", route: "/contact" },
    ];

    // constructor(private router: Router) {}

    constructor(private router: Router) {}

    ngOnInit() {}

    closeMenu(e) {
        UIkit.offcanvas("#offcanvas-flip").hide();
    }
    goToHire() {
        this.router.navigateByUrl("/hire-me");
    }
    navigateAndClose(route: string): void {
        this.router.navigateByUrl(route);
        const offcanvasElement = document.getElementById("offcanvas-flip");
        const offcanvasInstance = UIkit.offcanvas(offcanvasElement);
        offcanvasInstance.hide();
    }
}
