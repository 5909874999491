<!-- Start Mobile Navbar -->
<div id="offcanvas-flip" class="mobile-navbar uk-mobile-navbar" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">

        <button class="uk-offcanvas-close" type="button" uk-close></button>

        <!-- <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
            <ul class="uk-navbar-nav">
                <li>
                    <a (click)="navigateAndClose('/')" routerLinkActive="uk-active">Home</a>
                </li>
                <li>
                    <a (click)="navigateAndClose('/about')" routerLinkActive="uk-active">About</a>
                </li>
                <li>
                    <a (click)="navigateAndClose('/services')" routerLinkActive="uk-active">Services</a>
                </li>
                <li>
                    <a (click)="navigateAndClose('/hire-me')" routerLinkActive="uk-active">Hire Developer</a>
                </li>
                <li>
                    <a (click)="navigateAndClose('/contact')" routerLinkActive="uk-active">Contact</a>
                </li>
            </ul>
        </nav> -->
        <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
            <ul class="uk-navbar-nav">
                <li *ngFor="let menuItem of menuItems">
                    <a [routerLink]="menuItem.route" [routerLinkActive]="['uk-active']"
                        (click)="navigateAndClose(menuItem.route)">
                        {{ menuItem.label }}
                    </a>
                </li>
            </ul>
        </nav>

        <div class="social__icons">
            <ul class="social">
                <li><a href="https://www.linkedin.com/company/97881074/admin/feed/posts/" target="_blank"><i
                            class="flaticon-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="flaticon-logo"></i></a></li>
                <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="flaticon-logo-1"></i></a></li>
            </ul>
        </div>
    </div>
</div>
<!-- End Mobile Navbar -->

<!-- Start Navbar Area -->
<header class="header-area {{navClass}}" data-uk-sticky="top: 0; animation: uk-animation-slide-top;">
    <div class="uk-container">
        <div class="uk-navbar">
            <div class="logo uk-navbar-left">
                <a routerLink="/">
                    <img class="symbol mobile" src="/assets/img/logo/mobile-logo.png" alt="logo" width="10%">
                    <img src="/assets/img/logo/web-logo-name.png" alt="logo" width="40%">
                </a>
            </div>

            <div class="uk-navbar-toggle" id="navbar-toggle" uk-toggle="target: #offcanvas-flip">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="navbar uk-navbar-right">
                <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
                    <ul class="uk-navbar-nav">
                        <li>
                            <a routerLink="/" routerLinkActive="uk-active">Home</a>
                        </li>
                        <li>
                            <a routerLink="/about" routerLinkActive="uk-active">About</a>
                        </li>
                        <li>
                            <a routerLink="/services" routerLinkActive="uk-active">Services</a>
                        </li>
                        <li>
                            <a routerLink="/hire-me" routerLinkActive="uk-active">Hire Developer</a>
                        </li>
                        <li>
                            <a routerLink="/contact" routerLinkActive="uk-active">Contact</a>
                        </li>
                    </ul>
                </nav>
                <div class="pendulum">
                    <img (click)="goToHire()" src="/assets/img/hire developer/hire-developer.png" alt="Pendulum Image">
                </div>
            </div>
        </div>
    </div>
</header>
<!-- End Navbar Area -->