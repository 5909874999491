<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Hire Developer</h1>
        <!-- <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Hire Developer</li>
        </ul> -->
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Creative Partner Area -->
<!-- <div class="uk-creative-partner creative-partner-area with-bg-color ptb-100">
    <div class="uk-container">
        <div class="creative-partner-slides owl-carousel owl-theme">
            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner4.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner5.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item without-border">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner6.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div> -->
<!-- End Creative Partner Area -->

<!-- Start Marketing About Area -->
<div class="uk-creative-about marketing-about-area ptb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item uk-flex-middle">
                <div class="marketing-about-content top-zero">
                    <!-- <span>About Us</span> -->
                    <h3>Hire Skilled And Qualified Team
                        Of Developers For Advance
                        Web Development</h3>
                    <!-- <p>We provide marketing services to startups and small businesses to looking for a partner of their
                        digital media, design & development, lead generation and communications requirents. We work with
                        you, not for you. Although we have a great resources.
                    </p>
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item">
                            <div class="about-inner-card">
                                <h5>Our Mission</h5>
                                <p>We provide marketing services to startups and small businesses to looking for a
                                    partner.</p>
                            </div>
                        </div>

                        <div class="item">
                            <div class="about-inner-card">
                                <h5>Our Vision</h5>
                                <p>We provide marketing services to startups and small businesses to looking for a
                                    partner.</p>
                            </div>
                        </div>
                    </div>  -->
                    <div class="about-btn">
                        <a (click)="goToContactSection()" class="uk-button uk-button-default">Request a Free
                            Consultation</a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="marketing-about-image style-two">
                    <img src="/assets/img/hire developer/hire developer speaker.jpg" alt="image">

                    <!-- <div class="content">
                        <h4>15</h4>
                        <b>Years Experience</b>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Marketing About Area -->

<!-- Start Creative Services Area -->
<!-- <div class="uk-creative-services creative-services-area with-bg-image pb-100">
    <div class="uk-container pb-100">
        <div class="creative-funfacts-area pt-100 pb-70">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="450">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Satisfied Clients</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="485">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Completed Projects</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="100">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Team Members</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-creative-funfacts">
                        <h3>
                            <span class="odometer" data-count="150">00</span>
                            <span class="sign">+</span>
                        </h3>
                        <p>Award Winners</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>OUR SERVICES</span>
            <h2>All The Services That We Provide To Meet The Business Needs Of The Clients</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>01</span>
                        </div>
                        <h3>
                            <a href="#">Web Development</a>
                        </h3>
                        <p>
                            We are one of the top, client-oriented companies providing web development
                            services with concrete outcomes. We guarantee to produce excellent outcomes
                            since we recognise the value of an interesting and feature-rich website.
                        </p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>02</span>
                        </div>
                        <h3>
                            <a href="#">App Applications</a>
                        </h3>
                        <p>
                            We are skilled at putting the best techniques into practice so that
                            we can provide superior bespoke mobile app development services that meet
                            practically all of the needs of organizations.
                        </p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>03</span>
                        </div>
                        <h3>
                            <a href="#">E-Commerce Development</a>
                        </h3>
                        <p>
                            We offer customizable, scalable, and innovative e-commerce solutions to
                            businesses of all sizes at a cost-effective price to maximize their return on
                            investment. We follow industry-standard methods and tools to help implement a
                            flawless e-commerce platform in your organization.
                        </p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>04</span>
                        </div>
                        <h3>
                            <a href="#">ERP Software Development</a>
                        </h3>
                        <p>
                            We offer customizable, adaptable, and innovative ERP solutions to service
                            providers of all sizes at an affordable price to maximize their return on
                            investment. We follow industry-leading methods and tools to help implement a
                            faultless ERP system in your organization.
                        </p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>05</span>
                        </div>
                        <h3>
                            <a href="#">Automated Testing</a>
                        </h3>
                        <p>
                            We are a seasoned automated testing company that appreciates client requirements
                            and helps you validate the functionality of apps/websites in a continuous
                            integration workflow. Our extensive set of cutting-edge automation tools allows
                            our clients to select the best solution for their needs.
                        </p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box white-box">
                    <div class="content">
                        <div class="number">
                            <span>06</span>
                        </div>
                        <h3>
                            <a href="#">IT Staff Augmentation</a>
                        </h3>
                        <p>
                            If a lack of the right talent is slowing down your project delivery, hiring our
                            dedicated teams can be the perfect solution for you. Our dedicated teams will
                            work exclusively on your projects while being fully integrated into your team
                            and sitting in our well-established development center.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="view-all-services-btn">
            <a href="services.html">View Our All Services</a>
        </div>
    </div>
</div> -->
<!-- End Creative Services Area -->
<hr>

<!-- Boost your business area -->
<div class="full-width Qualified_Developer uk-about about-area uk-section inn_padding">
    <div class="container text-center">
        <h1>Boost Your Business Efficiency with our <span class="hightlight">Qualified Developers</span></h1>
        <p>
            Every smart business leader understands the need for advanced web development. And these results are only
            possible when you have an experienced team of professional developers who can leverage modern technologies.
            <br><br>
            The search for a reliable web development team ends at Zest India. We have professionals with the technical
            understanding and years of experience in the field. Their experience is the right weapon for your web
            development project. Our developers bring efficiency to the development of the site, which helps in
            providing quality results without project delays.
        </p>
        <!-- <a href="https://www.sinontechs.com/contact-us/" class="btn">Hire Now</a> -->
        <a (click)="goToContactSection()" class="text-center" class="uk-button uk-button-default">Hire Now</a>
    </div>
</div>

<!-- End Boost your business area -->
<hr>
<!-- How we deliever area start -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item highlighedBox">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <!-- <span>About Us</span> -->
                        <h2>HOW OUR DEVELOPERS
                            GET THE TASK DONE</h2>
                        <div class="bar"></div><br>
                    </div>

                    <div class="about-text">
                        <!-- <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div> -->
                        <h3>Your Business, Our Passion.</h3>
                        <br>
                        <p class="text-dark">
                            When you choose to hire our web developers, effective time management and a well-organized
                            process become integral to your project.
                            <br>
                            Here's a breakdown of how our skilled professionals
                            carry out the task:
                        </p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <!-- <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Who we are?</h2>
                        <div class="bar"></div>
                    </div> -->

                    <div class="about-text">
                        <!-- <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div> -->
                        <ul>
                            <li>
                                The first step involves meeting with the client to gather the important information
                                about their business.

                            </li> <br>
                            <li>
                                We do thorough research on the market and industry to come to better conclusions.
                            </li> <br>
                            <li>

                                The information and conclusions we gather are used to design the structure of the
                                website.
                            </li> <br>
                            <li>
                                Our developers work together with team members to write the code for the website
                                following a
                                pre-planned strategy.
                            </li> <br>
                            <li>
                                We test the website in multiple stages to catch and fix any errors.
                            </li> <br>
                            <li>
                                Once the website is developed, we show it to the client for their approval.
                            </li> <br>
                            <li>
                                If needed, we make suitable changes to the project based on the client's feedback.
                            </li> <br>
                        </ul>
                        <!-- <p>
                            Zest India is a global software services company that offers innovative, up-to-date, and
                            energetic services to all sizes of businesses. We are ISO 9001:2015 certified and have
                            successfully expanded our business globally, with offices in Singapore and the United
                            Kingdom.
                            <br><br>
                            Our team of more than 100 developers and designers is dedicated to meeting the client's
                            needs and delivering high-quality work on time. Our expertise and commitment to delivering
                            complex strategic IT projects make us a reliable service provider for more than 500
                            clients worldwide.
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
<!-- End How we deliever area  -->

<!-- Start Creative Projects Area -->
<!-- <div class="uk-creative-projects creative-projects-area with-bg-color ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>OUR COMPLETED PROJECTS</span>
            <h2>Take A Look At All The Projects That We Have Completed For The Client</h2>
        </div>
    </div>

    <div class="uk-container-expand">
        <div class="creative-projects-slides-three owl-carousel owl-theme">
            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects1.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Marketing</span>
                    <h3>
                        <a href="single-project.html">Affiliate Marketing</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects2.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Mobile App</span>
                    <h3>
                        <a href="single-project.html">Mobile Applications</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects3.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Hosting</span>
                    <h3>
                        <a href="single-project.html">Web Hosting</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects4.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Development</span>
                    <h3>
                        <a href="single-project.html">Web Development</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects5.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Bridge</span>
                    <h3>
                        <a href="single-project.html">E-commerce</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects1.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Marketing</span>
                    <h3>
                        <a href="single-project.html">Affiliate Marketing</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects2.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Mobile App</span>
                    <h3>
                        <a href="single-project.html">Mobile Applications</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects3.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Hosting</span>
                    <h3>
                        <a href="single-project.html">Web Hosting</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects4.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Development</span>
                    <h3>
                        <a href="single-project.html">Web Development</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects5.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Bridge</span>
                    <h3>
                        <a href="single-project.html">E-commerce</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Creative Projects Area -->

<!-- Start Creative Pricing Area -->
<!-- <section class="uk-creative-pricing creative-pricing-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>OUR PRICES</span>
            <h2>Our Flexible Pricing Plan</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-pricing-box with-bg-image">
                    <div class="pricing-header">
                        <h3>Starter Plan</h3>
                    </div>

                    <div class="price">
                        $49.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box with-bg-image">
                    <div class="pricing-header">
                        <h3>Advance Plan</h3>
                    </div>

                    <div class="price">
                        $59.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box with-bg-image">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="price">
                        $99.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Creative Pricing Area -->

<!-- Start Creative Video Area -->
<!-- <section class="uk-creative-video creative-video-area">
    <div class="uk-container">
        <div class="creative-video-image">
            <img src="assets/img/home-nine/video.jpg" alt="image">
            <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i
                    class='bx bx-play'></i></a>
        </div>
    </div>
</section> -->
<!-- End Creative Video Area -->

<!-- Start Creative Process Area -->
<!-- <section class="uk-creative-process creative-process-area with-bg-color pt-100 pb-70">
    <div class="uk-container">
        <div class="uk-text-center uk-flex-middle" uk-grid>
            <div class="uk-width-1-4">
                <div class="section-title-with-big-text top-zero">
                    <span>PROCESS</span>
                    <h2>Easy Ways To Get Ready Your Work</h2>
                </div>
            </div>

            <div class="uk-width-3-4">
                <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                    <div class="uk-item">
                        <div class="creative-process-card with-bg-color">
                            <div class="icon">
                                <i class="flaticon-project"></i>
                            </div>
                            <h3>
                                <a href="#">Project Research</a>
                            </h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis consectetur adipiscing.</p>
                        </div>
                    </div>

                    <div class="uk-item">
                        <div class="creative-process-card with-bg-color">
                            <div class="icon">
                                <i class="flaticon-ux-design"></i>
                            </div>
                            <h3>
                                <a href="#">Project Design</a>
                            </h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis consectetur adipiscing.</p>
                        </div>
                    </div>

                    <div class="uk-item">
                        <div class="creative-process-card with-bg-color">
                            <div class="icon">
                                <i class="flaticon-think"></i>
                            </div>
                            <h3>
                                <a href="#">Project Launch</a>
                            </h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis consectetur adipiscing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Creative Process Area -->
<hr>
<!-- Start Creative Team Area -->
<section class="uk-creative-team creative-team-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <h2>Onboard Senior Developers Committed to Aligning with Your <span class="highlight">Time Zone, Deadlines,
                    and
                    Milestones</span></h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/Android.png" alt="image">
                        <p>
                            <strong>Hire Android <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/iOS.png" alt="image">
                        <p>
                            <strong>Hire iOS <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/Angular.png" alt="image">
                        <p>
                            <strong>Hire Angular <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/DotNet.png" alt="image">
                        <p>
                            <strong>Hire ASP .NET <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/Java.png" alt="image">
                        <p>
                            <strong>Hire Java <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/React.png" alt="image">
                        <p>
                            <strong>Hire ReactJs <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/Php.png" alt="image">
                        <p>
                            <strong>Hire Php <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/NodeJs.png" alt="image">
                        <p>
                            <strong>Hire NodeJs <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/Mean.png" alt="image">
                        <p>
                            <strong>Hire MEAN Stack <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/Mern.png" alt="image">
                        <p>
                            <strong>Hire MERN Stack <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/Firebase.png" alt="image">
                        <p>
                            <strong>Hire Firebase <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="/assets/img/hire developer/UIUX.png" alt="image">
                        <p>
                            <strong>Hire UI/UX <br> Developer</strong>
                        </p>
                    </div>
                </div>
            </div>


            <!-- <div class="item">
                <div class="creative-team-box without-border-radius">
                    <div class="team-image">
                        <img src="assets/img/home-nine/team/team4.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Olivia Smith</h3>
                        <span>Front-End Developer</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Creative Team Area -->

<!-- Start Creative Reviews Area -->
<!-- <div class="uk-creative-reviews creative-reviews-area pb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-reviews-img">
                    <img src="assets/img/home-nine/reviews.jpg" alt="image">

                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <div class="creative-reviews-slides owl-carousel owl-theme">
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                            viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>

                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                            viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Creative Reviews Area -->

<!-- Start Creative News Area -->
<!-- <section class="uk-creative-news creative-news-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>Latest News</span>
            <h2>Read Our Latest News</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-news-box">
                    <div class="news-image">
                        <a href="single-blog.html">
                            <img src="assets/img/home-nine/news/news1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="news-content">
                        <ul class="meta">
                            <li><a href="#">Web</a></li>
                            <li>02-02-2022</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                        </h3>
                        <div class="info">
                            <img src="assets/img/client1.png" alt="image">

                            <div class="title">
                                <h4>By <a href="#">Burnett</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-news-box">
                    <div class="news-image">
                        <a href="single-blog.html">
                            <img src="assets/img/home-nine/news/news2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="news-content">
                        <ul class="meta">
                            <li><a href="#">Development</a></li>
                            <li>02-02-2022</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                        </h3>
                        <div class="info">
                            <img src="assets/img/client2.png" alt="image">

                            <div class="title">
                                <h4>By <a href="#">Jimmie</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-news-box">
                    <div class="news-image">
                        <a href="single-blog.html">
                            <img src="assets/img/home-nine/news/news3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="news-content">
                        <ul class="meta">
                            <li><a href="#">Design</a></li>
                            <li>02-02-2022</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                        </h3>
                        <div class="info">
                            <img src="assets/img/client3.png" alt="image">

                            <div class="title">
                                <h4>By <a href="#">Rodriguez</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Creative News Area -->
<hr>
<!-- Start Creative Contact Area -->
<div class="uk-creative-contact creative-contact-area ptb-100" id="contactForConsultation">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">

            <h2>
                <span>Got A Project?</span>

            </h2>
            <h3>Let's Talk About It</h3>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-contact-image with-wrap-color">
                    <img src="/assets/img/contact/contact.jpg" alt="image">
                </div>
            </div>

            <div class="item uk-flex-middle">
                <form class="creative-contact-form" [formGroup]="consultationForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Name</label>
                            <input type="text" class="uk-input" placeholder="John Doe" formControlName="name">
                            <span
                                *ngIf="consultationForm.controls['name'].touched && consultationForm.controls['name'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Email</label>
                            <input type="email" class="uk-input" placeholder="john@gmail.com" formControlName="email">
                            <span
                                *ngIf="consultationForm.controls['email'].touched && consultationForm.controls['email'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Phone</label>
                            <input type="number" class="uk-input" placeholder="+1-212-456-7890" formControlName="mobile">
                            <span
                                *ngIf="consultationForm.controls['mobile'].touched && consultationForm.controls['mobile'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Subject</label>
                            <input type="text" class="uk-input" placeholder="Your subject here"
                                formControlName="subject">
                            <span
                                *ngIf="consultationForm.controls['subject'].touched && consultationForm.controls['subject'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>
                    </div>

                    <div class="item">
                        <label class="uk-form-label" for="form-stacked-text">Your message</label>
                        <textarea class="uk-textarea" cols="10" rows="2" formControlName="message"
                            placeholder="Write your message here..."></textarea>
                    </div>

                    <!-- <div class="checkbox-boxes">
                        <label><input class="uk-checkbox" type="checkbox"> By Checking This, You Agree To Our <a
                                href="#">Terms</a> And <a href="#">Privacy Policy</a></label>
                    </div> -->

                    <button [disabled]="!consultationForm?.valid" (click)="onSubmit(consultationForm)" type="submit"
                        class="uk-button uk-button-default my-3">Submit
                        Message</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Contact Area -->