<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services Details</h1>
        <!-- <ul>
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="/services">Services Details/{{serviceForBreadCrumb}}</a></li>
        </ul> -->
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <div class="back__to__service my-5">
            <a routerLink="/services">
                <img src="../../../../assets/img/services/pointer.png" alt="pointer" width="25px">
                Back To Services</a>
        </div>
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand" *ngIf="selecetedService && selecetedService ==='web_development'">
                    <div class="services-details-desc">
                        <h3>Web Development</h3>
                        <p>In today's digital world, a website is essential for any business. It is your online
                            storefront, your marketing hub, and your customer service center. At Zest India, we
                            understand the importance of having a high-quality website that meets your business needs.
                            <br> <br>
                            Our team of experienced developers can create custom websites that are both beautiful and
                            functional. We use the latest web development technologies to ensure that your website is
                            fast, secure, and accessible to all users. We also offer ongoing support and maintenance to
                            ensure that your website is always up-to-date and running smoothly.
                            <br><br>
                            Whether you are looking for a simple website or a complex enterprise solution, we can help
                            you create a website that will help you achieve your business goals.

                        </p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/services/web/Web-one.jpg"
                                    alt="Web Development service at Zest India">
                            </div>

                            <div class="item">
                                <img src="assets/img/services/web/Web-two.jpg" alt="Web Development service at Zest ">
                            </div>

                            <div class="item">
                                <img src="assets/img/services/web/Web-three.jpg" alt="Web Development service at Zest ">
                            </div>
                        </div>
                        <h3>Your Website is Your Business's Online Presence.</h3>
                        <ul class="services-features-list">
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Online
                                Presence
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Customer Engagement
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Brand Credibility
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Custom Solutions
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Competitive Edge
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Business Growth
                            </li>
                        </ul>
                        <hr>





                        <!-- <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Which material types can you work with?
                                    </a>

                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Is Smart Lock required for instant apps?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple activities in a single feature?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I share resources between features?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div> -->

                    </div>
                </div>
                <div class="inner uk-width-expand" *ngIf="selecetedService && selecetedService ==='app_development'">
                    <div class="services-details-desc">
                        <h3>App Development</h3>
                        <p>Mobile apps have revolutionized the way we interact with the digital world. They have made it
                            possible for businesses to reach millions of users instantly and stay connected with their
                            customers at all times.
                            <br> <br>
                            At Zest India, we understand the power of mobile apps and we can help you create a
                            successful mobile app strategy that delivers results. We have a team of experienced
                            professionals who can design, develop, and deploy mobile apps that meet your specific needs.
                            <br><br>
                            We also specialize in cross-platform app development, which means that your app can be used
                            on multiple devices, such as iPhones, iPads, Android devices, and Windows devices. This
                            allows you to reach a wider audience with your app and increase your chances of success.
                        </p>
                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="/assets/img/services/app/app-one.jpg" alt="App Development service at Zest ">
                            </div>
                            <div class="item">
                                <img src="/assets/img/services/app/app-tow.jpg" alt="App Development service at Zest ">
                            </div>
                            <div class="item">
                                <img src="/assets/img/services/app/app-four.jpg" alt="App Development service at Zest ">
                            </div>
                        </div>
                        <h3>An App Can Transform Your Business</h3>
                        <ul class="services-features-list">
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Mobile Reach
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Enhanced Engagement
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Custom Solutions
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Competitive Advantage
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Business Growth
                            </li>

                        </ul>

                        <!-- <blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam
                                rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote> -->

                        <!-- <h3>Our Work Benefits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Which material types can you work with?
                                    </a>

                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Is Smart Lock required for instant apps?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple activities in a single feature?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I share resources between features?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="inner uk-width-expand"
                    *ngIf="selecetedService && selecetedService ==='e-commerce_development'">
                    <div class="services-details-desc">
                        <h3>E-Commerce Development</h3>
                        <p>We at Zest India understand your requirements for e-commerce development. We have been
                            providing high-quality e-commerce development services since inception, and we are focused
                            on client satisfaction. We allocate dedicated managers from the day one of the project
                            development life cycles, and we ensure regular communication and interaction with our
                            clients. This is why our company is considered as the best choice from website design
                            development companies in India.
                            <br><br>
                            Zest India also focuses on the most affordable solutions for the clients. This means that
                            our clients can get high-quality e-commerce development services at the best price. This
                            gives our clients the best ROI towards their e-commerce development projects.
                        </p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="/assets/img/services/e-com/E-commerce-one.jpg"
                                    alt="E-Commerce Development at Zest Inida">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/e-com/E-commerce-two.jpg"
                                    alt="E-Commerce Development at Zest Inida">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/e-com/E-commerce-three.jpg"
                                    alt="E-Commerce Development at Zest Inida">
                            </div>
                        </div>
                        <h3>E-Commerce: The Future of Retail</h3>
                        <ul class="services-features-list">
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Online Sales
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Customer Convenience
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Brand Exposure
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Custom Solutions
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Competitive Edge
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Business Growth
                            </li>
                        </ul>

                        <!-- <blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam
                                rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>

                        <h3>Our Work Benefits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Which material types can you work with?
                                    </a>

                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Is Smart Lock required for instant apps?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple activities in a single feature?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I share resources between features?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="inner uk-width-expand"
                    *ngIf="selecetedService && selecetedService ==='erp-software_development'">
                    <div class="services-details-desc">
                        <h3>ERP Software Development</h3>
                        <p>In the arena of ERP software development we offer innovative, configurable, and flexible ERP
                            solutions that are tailored to your specific needs. We follow industry-standard methods and
                            tools to help you implement a flawless ERP system in your organization, at a minimized cost
                            to maximize your return on investment.
                            <br><br>
                            Our ERP solutions are designed to help you streamline your operations, improve efficiency,
                            and make better decisions. We can help you integrate your business processes, manage your
                            inventory, track your finances, and automate your workflows.
                            <br><br>
                            We also offer a wide range of support and maintenance services to ensure that your ERP
                            system is always up-to-date and running smoothly.
                        </p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="/assets/img/services/erp/ERP-one.jpg"
                                    alt="ERP Software Development at Zest India">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/erp/ERP-two.jpg"
                                    alt="ERP Software Development at Zest India">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/erp/ERP-three.jpg"
                                    alt="ERP Software Development at Zest India">
                            </div>
                        </div>
                        <h3>ERP: The Future of Business Management</h3>
                        <ul class="services-features-list">
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Streamlined Operations
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Data Management
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Custom Solutions
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Competitive Edge
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Scalability
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Business Insights
                            </li>
                        </ul>

                        <!-- <blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore Totam
                                rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>

                        <h3>Our Work Benefits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Which material types can you work with?
                                    </a>

                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Is Smart Lock required for instant apps?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple activities in a single feature?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I share resources between features?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="inner uk-width-expand" *ngIf="selecetedService && selecetedService ==='automated_testing'">
                    <div class="services-details-desc">
                        <h3>Automated Testing</h3>
                        <p>
                            We are an experienced automated testing company that understands your needs and helps you
                            verify the functionality of your apps and websites in a continuous integration workflow.
                            We offer a wide range of advanced automation tools that allow you to choose the best
                            solution for your specific requirements.
                        </p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="/assets/img/services/testing/Testing-one.jpg"
                                    alt="Automated Testing at Zest India">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/testing/Testing-two.jpg"
                                    alt="Automated Testing at Zest India">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/testing/Testing-three.jpg"
                                    alt="Automated Testing at Zest India">
                            </div>
                        </div>
                        <h3>The Key to a Smooth, Efficient, and Bug-Free Launch</h3>
                        <ul class="services-features-list">
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Efficiency Boost
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Error Detection
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Regression Testing

                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Custom Solutions
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Quality Assurance

                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Cost-Efficiency
                            </li>
                        </ul>

                        <!-- <blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore
                                Totam
                                rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>

                        <h3>Our Work Benefits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt
                            ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit
                            in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                            laborum.
                        </p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Which material types can you work with?
                                    </a>

                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Is Smart Lock required for instant apps?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple activities in a single feature?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I share resources between features?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="inner uk-width-expand"
                    *ngIf="selecetedService && selecetedService ==='it-staff_augmentation'">
                    <div class="services-details-desc">
                        <h3>IT Staff Augmentation</h3>
                        <p>
                            If project delays stem from a lack of suitable talent, engaging our dedicated teams could
                            provide the ideal remedy. Our dedicated teams are entirely committed to your projects
                            and
                            operate from our well-established development center.
                            <br><br>
                            Zest India delivers excellence through cost-efficient means. Opting for our dedicated
                            team
                            services could lead to savings of up to 60% on your development expenses. Our dedicated
                            team
                            hiring solution spans various technologies, encompassing <b> Node.js, Angular, React, Java,
                                Firebase, MongoDB, HTML5, Magento, PHP, ASP.NET, Drupal, MySQL, MS SQL, and more.</b>
                            Our
                            adept
                            project managers oversee project progression, while our skilled Graphics Designers
                            conceive
                            innovative and lucrative solutions.
                        </p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="/assets/img/services/it-staff/IT Staff augmentation-one.jpg"
                                    alt="IT staff Augmentation">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/it-staff/IT Staff augmentation-two.jpg"
                                    alt="IT staff Augmentation">
                            </div>

                            <div class="item">
                                <img src="/assets/img/services/it-staff/IT Staff augmentation- three.jpg"
                                    alt="IT staff Augmentation">
                            </div>
                        </div>
                        <h3>Get the IT talent you need, when you need it</h3>
                        <ul class="services-features-list">
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Skill Enhancement
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Resource Flexibility
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Custom Solutions
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Faster Delivery
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Cost Efficiency
                            </li>
                            <li>
                                <img class="reversePointer" src="/assets/img/services/pointer.png" alt="pointer">
                                Access to Expertise
                            </li>
                        </ul>

                        <!-- <blockquote class="blockquote">
                            <p>Sed ut perspiciatis undeomnis iste natus error sit voluptatem accusantium dolore
                                Totam
                                rem aperiam with a long list of products and never ending customer support.</p>
                        </blockquote>

                        <h3>Our Work Benefits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                            in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Which material types can you work with?
                                    </a>

                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Is Smart Lock required for instant apps?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I have multiple activities in a single feature?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Can I share resources between features?
                                    </a>

                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis
                                        ipsum suspendisse ultrices gravida.</p>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <!-- <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div> -->

                    <div class="widget service_list">
                        <ul>
                            <li>
                                <a (click)="goToDetails('web_development')"
                                    [class.active]="selectedNavItem === 'web_development'">Web Development <i
                                        class="flaticon-right"></i>
                                </a>
                            </li>
                            <li>
                                <a (click)="goToDetails('app_development')"
                                    [class.active]="selectedNavItem === 'app_development'">App Development<i
                                        class="flaticon-right"></i>
                                </a>
                            </li>
                            <li>
                                <a (click)="goToDetails('e-commerce_development')"
                                    [class.active]="selectedNavItem === 'e-commerce_development'">E-Commerce Development
                                    <i class="flaticon-right"></i>
                                </a>
                            </li>
                            <li>
                                <a (click)="goToDetails('erp-software_development')"
                                    [class.active]="selectedNavItem === 'erp-software_development'">ERP Software
                                    Development<i class="flaticon-right"></i>
                                </a>
                            </li>
                            <li>
                                <a (click)="goToDetails('automated_testing')"
                                    [class.active]="selectedNavItem === 'automated_testing'">Automated Testing <i
                                        class="flaticon-right"></i>
                                </a>
                            </li>
                            <li>
                                <a (click)="goToDetails('it-staff_augmentation')"
                                    [class.active]="selectedNavItem === 'it-staff_augmentation'">IT Staff Augmentation
                                    <i class="flaticon-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="widget widget_download">
                        <h3 class="widget-title">Download</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="#">Our Brochure Pdf <i class="flaticon-edit"></i></a></li>
                            <li><a href="#">Our Iso Certificate <i class="flaticon-edit"></i></a></li>
                            <li><a href="#">Great Technology <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </article>
    </div>
</section>
<div class="container mb-5">
    <div class="text-center">
        <h3>What To Expect From Us</h3>
        <blockquote class="blockquote">
            <p>
                We will help you every step of the way and get you the results that will help you to
                grow your business.
            </p>
        </blockquote>
    </div>
    <div class="row mt-5">
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <strong>
                        CUSTOMIZED SOLUTIONS
                    </strong>
                </div>
                <div class="card-body">
                    <p>
                        We believe that every business is unique, and that's why we customize every solution as per your
                        business requirements.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <strong>
                        SAVE MONEY & TIME
                    </strong>
                </div>
                <div class="card-body">
                    <p>
                        We'll provide you with top-notch technology solutions that fit your budget, ensuring timely
                        project delivery to keep our clients satisfied.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <strong>
                        SKILLED DEVELOPERS
                    </strong>
                </div>
                <div class="card-body">
                    <p>
                        We have a team of experienced and skilled developers who are well-equipped to handle even the
                        most challenging projects.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <strong>
                        24/7 DEDICATED SUPPORT
                    </strong>
                </div>
                <div class="card-body">
                    <p>
                        We're here to give you excellent technical support. Whenever you need assistance, our dedicated
                        24/7 support team is ready to help.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <strong>
                        INTERACTIVE DESIGNS
                    </strong>
                </div>
                <div class="card-body">
                    <p>
                        Our team of in-house developers uses a special method to craft attractive and captivating
                        designs that contribute to turning visitors into customers.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <strong>
                        DELIVERING HIGH QUALITY SOLUTIONS
                    </strong>
                </div>
                <div class="card-body">
                    <p>
                        We use high-quality standards and innovation to deliver complete software development services
                        that address both present and future requirements.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Details Area -->