<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services</h1>
        <!-- <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Services</li>
        </ul> -->
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="/assets/img/services/web-logo.png" alt="Web Development">
                    </div>
                    <h3>Web Development</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a (click)="goToDetails('web_development')" class="link uk-position-cover"></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="/assets/img/services/app-logo.png" alt="App development">
                    </div>
                    <h3>App Development</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a (click)="goToDetails('app_development')" class="link uk-position-cover"></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="/assets/img/services/e-commerce-logo.png" alt="E-Commerce Development">
                    </div>
                    <h3>E-Commerce Development</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a (click)="goToDetails('e-commerce_development')" class="link uk-position-cover"></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="/assets/img/services/erp-logo.png" alt="ERP Software Development">
                    </div>
                    <h3>ERP Software Development</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a (click)="goToDetails('erp-software_development')" class="link uk-position-cover"></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="/assets/img/services/automated-testing-logo.png" alt="Automated Testing">
                    </div>
                    <h3>Automated Testing</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a (click)="goToDetails('automated_testing')" class="link uk-position-cover"></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <img src="/assets/img/services/it-staff-logo.png" alt="IT Staff Augmentation">
                    </div>
                    <h3>IT Staff Augmentation</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a (click)="goToDetails('it-staff_augmentation')" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Process Area -->
<section class="process-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Process</span>
            <h2>how We <span>Work</span></h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="file-text"></i>
                    </div>
                    <h3>Requirement</h3>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i class="fas fa-cogs"></i>
                    </div>
                    <h3>Designing</h3>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="code"></i>
                    </div>
                    <h3>Development</h3>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="heart"></i>
                    </div>
                    <h3>Testing</h3>
                </div>
            </div>

            <div class="item">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>

<!-- End Process Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container text-center">
        <h3>Got a great idea or looking for a remote dedicated team?
            Simply reach us and see <br><span class="highlight">what we can do for you.</span></h3>

        <button type="submit" (click)="goToContact()" class="uk-button uk-button-default mt-5">Subscribe Now</button>
    </div>

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>
</section>
<!-- End Subscribe Area -->