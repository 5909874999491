import { Component, OnInit } from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
} from "@angular/forms";
@Component({
    selector: "app-creative-agency",
    templateUrl: "./creative-agency.component.html",
    styleUrls: ["./creative-agency.component.scss"],
})
export class CreativeAgencyComponent implements OnInit {
    consultationForm!: FormGroup;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.consultationForm = this.formBuilder.group({
            name: new FormControl("", [Validators.required]),
            email: new FormControl("", [
                Validators.required,
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ]),
            mobile: new FormControl("", [
                Validators.required,
                Validators.maxLength(12),
            ]),
            subject: new FormControl("", [Validators.required]),
            message: new FormControl("", [Validators.required]),
        });
    }

    goToContactSection() {
        const id = document.getElementById("contactForConsultation");
        id.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
    onSubmit(formData: any) {
        debugger;
        let data = formData?.value;
        alert("Thanks for the Response!!");
        this.consultationForm.reset();
    }
}
