<!-- Start Main Banner -->
<div id="home" class="banner-section">
    <div class="uk-container-expand">
        <!-- <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"> -->
        <!-- <div class="item"> -->
        <div class="banner-content">
            <div class="d-table">
                <div class="d-table-cell">
                    <!-- <video autoplay loop muted class="background-video">
                        <source src="../../../../assets/video/Technology Background.mp4">
                        Your browser does not support the video tag.
                    </video> -->
                    <div class="content">
                        <h1>Your Trusted IT Partner for <span>Reliable Solutions</span></h1>
                        <p>We are a fully-fledged IT services company that is transforming the technologically-driven
                            generation by invading new opportunities.</p>
                        <div class="btn-box">
                            <a routerLink="/contact" class="uk-button uk-button-default">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- </div> -->
            <!-- </div> -->

            <!-- <div class="item">
                <div class="banner-image">
                    <img src="" alt="image">
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Main Banner -->

<!-- Start Services Area -->
<section class="uk-services services-section uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Services We Offer</span>
            <h2>We are modern & special for designing</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="services-box">
                    <img src="/assets/img/services/web.jpg" alt="image">

                    <div class="content">
                        <div class="icon">
                            <img src="/assets/img/services/web-logo.png" alt="Web Development">
                        </div>
                        <h3>Web Development</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <img src="/assets/img/services/web-logo.png" alt="Web Development">
                                    </div>
                                    <h3>Web Development</h3>
                                    <p>
                                        We are one of the top, client-oriented companies providing web development
                                        services with concrete outcomes. We guarantee to produce excellent outcomes
                                        since we recognise the value of an interesting and feature-rich website.
                                    </p>
                                    <a routerLink="/service-details" class="details-btn"><i
                                            class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="services-box">
                    <img src="/assets/img/services/app.jpg" alt="image">
                    <div class="content">
                        <div class="icon">
                            <img src="/assets/img/services/app-logo.png" alt="App development">
                        </div>
                        <h3>App Development</h3>
                    </div>
                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <img src="/assets/img/services/app-logo.png" alt="App Development">
                                    </div>
                                    <h3>App Development</h3>
                                    <p>
                                        We are skilled at putting the best techniques into practice so that
                                        we can provide superior bespoke mobile app development services that meet
                                        practically all of the needs of organizations.
                                    </p>
                                    <a routerLink="/service-details" class="details-btn"><i
                                            class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="services-box">
                    <img src="/assets/img/services/e-commerce.jpg" alt="E-Commerce Development">

                    <div class="content">
                        <div class="icon">
                            <img src="/assets/img/services/e-commerce-logo.png" alt="E-Commerce Development">
                        </div>
                        <h3>E-Commerce Development</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <img src="/assets/img/services/e-commerce-logo.png"
                                            alt="E-Commerce Development">
                                    </div>
                                    <h3>E-Commerce Development</h3>
                                    <p>
                                        We offer customizable, scalable, and innovative e-commerce solutions to
                                        businesses of all sizes at a cost-effective price to maximize their return on
                                        investment. We follow industry-standard methods and tools to help implement a
                                        flawless e-commerce platform in your organization.
                                    </p>
                                    <a routerLink="/service-details" class="details-btn"><i
                                            class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="services-box">
                    <img src="/assets/img/services/erp.jpg" alt="ERP Software Development">

                    <div class="content">
                        <div class="icon">
                            <img src="/assets/img/services/erp-logo.png" alt="ERP Software Development">
                        </div>
                        <h3>ERP Software Development</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <img src="/assets/img/services/erp-logo.png" alt="ERP Software Development">
                                    </div>
                                    <h3>ERP Software Development</h3>
                                    <p>
                                        We offer customizable, adaptable, and innovative ERP solutions to service
                                        providers of all sizes at an affordable price to maximize their return on
                                        investment. We follow industry-leading methods and tools to help implement a
                                        faultless ERP system in your organization.
                                    </p>
                                    <a routerLink="/service-details" class="details-btn"><i
                                            class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="services-box">
                    <img src="/assets/img/services/testing.jpg" alt="Automated Testing">
                    <div class="content">
                        <div class="icon">
                            <img src="/assets/img/services/automated-testing-logo.png" alt="Automated Testing">
                        </div>
                        <h3>Automated Testing</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <img src="/assets/img/services/automated-testing-logo.png"
                                            alt="Automated Testing">
                                    </div>
                                    <h3>Automated Testing</h3>
                                    <p>
                                        We are a seasoned automated testing company that appreciates client requirements
                                        and helps you validate the functionality of apps/websites in a continuous
                                        integration workflow. Our extensive set of cutting-edge automation tools allows
                                        our clients to select the best solution for their needs.
                                    </p>
                                    <a routerLink="/service-details" class="details-btn"><i
                                            class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="services-box">
                    <img src="/assets/img/services/staff-augmentation.jpg" alt="IT Staff Augmentation">
                    <div class="content">
                        <div class="icon">
                            <img src="/assets/img/services/it-staff-logo.png" alt="IT Staff Augmentation">
                        </div>
                        <h3>IT Staff Augmentation</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <img src="/assets/img/services/it-staff-logo.png" alt="IT Staff Augmentation">

                                    </div>
                                    <h3>IT Staff Augmentation</h3>
                                    <p>
                                        If a lack of the right talent is slowing down your project delivery, hiring our
                                        dedicated teams can be the perfect solution for you. Our dedicated teams will
                                        work exclusively on your projects while being fully integrated into your team
                                        and sitting in our well-established development center.
                                    </p>
                                    <a routerLink="/service-details" class="details-btn"><i
                                            class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-image">
                    <img src="/assets/img/who we are/who we are.jpg" alt="image">
                    <!-- <img src="/assets/img/who we are/who we are1.jpg" alt="image" width="50%"> -->
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Who we are?</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!-- <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div> -->
                        <h3>Your Business, Our Passion.</h3>
                        <p>
                            Zest India is a global software services company that offers innovative, up-to-date, and
                            energetic services to all sizes of businesses. We are ISO 9001:2015 certified and have
                            successfully expanded our business globally, with offices in Singapore and the United
                            Kingdom.
                            <br><br>
                            Our team of more than 100 developers and designers is dedicated to meeting the client's
                            needs and delivering high-quality work on time. Our expertise and commitment to delivering
                            complex strategic IT projects make us a reliable service provider for more than 500
                            clients worldwide.
                        </p>
                        <div class="signature">
                            <img src="/assets/img/who we are/About us logo.jpeg.jpg" alt="signature" width="25%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Process Area -->
<section class="process-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Process</span>
            <h2>Easy ways to get ready your work</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="receiver"></i>
                    </div>
                    <h3>Contact Us First</h3>
                    <p>You can reach us through our website, email, or phone number.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cog"></i>
                    </div>
                    <h3>Consult With Us</h3>
                    <p>We'll schedule a call or meeting to discuss your needs and how we can help.
                    </p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="users"></i>
                    </div>
                    <h3>Partner With Us</h3>
                    <p>We'll sign a contract and start working on your project.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="home"></i>
                    </div>
                    <h3>Give Us Payment!</h3>
                    <p>You can pay us through wire transfer, PayPal, or credit card.</p>
                </div>
            </div>

            <div class="item">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<!-- End Process Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Creative Reviews Area -->
<section id="testimonials" class="feedback-section uk-section">
    <div class="uk-container">
        <div class="uk-grid" uk-grid>
            <div class="uk-width-1-3@m">
                <div class="uk-section-title section-title">
                    <span>Testimonials</span>
                    <h2>What Clients Say About Us</h2>
                    <div class="bar"></div>
                </div>
            </div>

            <div class="uk-width-expand@m">
                <div class="feedback-slides-two owl-carousel owl-theme">
                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            I highly recommend Zest India. They are a very professional and efficient company that was
                            easily able to do everything I required for a mobile application and web interface. The
                            price was fair and they were able to complete everything in the time provided. This was done
                            through daily contact and weekly updates on the project versions. If you are browsing
                            companies, I can definitely say that Zest India is a 5-star company like the reviews say.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/fabtech.jpeg" alt="Zest India IT Services">
                            <h3>Ameet R.</h3>
                            <span>CTO at Fabtech Projects & Engg. Ltd</span>
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            I had a very smooth working experience with Zest India over a 6-month period. They
                            communicated well with me, understood my requirements, and worked on them. They did not
                            overpromise on features and were very transparent about their deliverables. I am very happy
                            with their overall work.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/kommu.jpeg" alt="Zest India IT Services">
                            <h3>Abrams B.</h3>
                            <span>CO-Founder at Kommu</span>
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            Hired for a complex app, met my expectations. Already plan to hire again on future projects.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/lumistella.jpeg" alt="Zest India IT Services">
                            <h3>Armando O.</h3>
                            <span>Digital Design Manager at The Lumistella Company</span>
                        </div>
                    </div>
                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            Our project was rather complicated and difficult, but the team at Zest India managed to
                            handle it with great patience and care. They followed each step of the process and listened
                            to my business requirements. Several issues arose while putting the changes live on my
                            website, and this is when the team showed their professionalism. They managed to resolve all
                            issues one after another with good attention and time management. I would definitely
                            recommend working with Zest India.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/delux.jpeg" alt="Zest India IT Services">
                            <h3>Yogesh B.</h3>
                            <span>Assistant manager at Delux Bearing</span>
                        </div>
                    </div>
                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            Great work! They are excellent communicator and work is great!
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/market-dojo.jpeg" alt="Zest India IT Services">
                            <h3>Niholas M.</h3>
                            <span>CO-Founder at Market Dojo</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Reviews Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Project Area -->
<!-- <section id="project" class="project-section uk-project uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Our Completed Projects</span>
            <h2>Recent Projects</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project1.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">Development</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Mobile</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project2.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">Architecture</a></h3>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Bridge</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project3.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">UX/UI Design</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Mobile</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section> -->
<!-- End Project Area -->

<!-- Start Funfacts Area -->
<section class="funfacts-area uk-section uk-funfacts mb-5">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="users"></i>
                    </div>
                    <h3 class="odometer" data-count="450">00</h3>
                    <p>Satisfied Clients</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="heart"></i>
                    </div>
                    <h3 class="odometer" data-count="485">00</h3>
                    <p>Completed Projects</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="user"></i>
                    </div>
                    <h3 class="odometer" data-count="100">00</h3>
                    <p>Team Members</p>
                </div>
            </div>

            <div class="item">
                <div class="single-funfacts">
                    <div class="icon">
                        <i uk-icon="unlock"></i>
                    </div>
                    <h3 class="odometer" data-count="150">00</h3>
                    <p>Award Winners</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Funfacts Area -->

<!-- Start Blog Area -->
<!-- <section id="blog" class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Our Company Blog</span>
            <h2>Latest News</h2>
            <div class="bar"></div>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">26 April</span>
                    <h3><a routerLink="/blog-details">11 Tools to Help You Easily Create Proposals</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">27 April</span>
                    <h3><a routerLink="/blog-details">The Outlook for Digital Agencies in 4 Charts</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->

<!-- Start Partner Area -->
<!-- <div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner Area -->

<!-- Start Contact Area -->
<!-- <section id="contact" class="contact-section uk-contact mt-5">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="contact-image">
                    <img src="assets/img/contact-image.jpg" alt="image">

                    <div class="contact-info">
                        <h3>27 Division St, New York, NY 10002, USA</h3>
                        <ul>
                            <li>Email: <a href="mailto:hello@baylin.com">hello@baylin.com</a></li>
                            <li>Phone: <a href="tel:+321948754">+ (321) 948 754</a></li>
                            <li>Fax: <a href="tel:+123849457">+ (123) 849 457</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="contact-form">
                    <div class="uk-section-title section-title">
                        <span>Let’s Talk</span>
                        <h2>Want to work with our team?</h2>
                        <div class="bar"></div>
                    </div>

                    <form id="contactForm">
                        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                            <div class="item uk-margin">
                                <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
                            </div>

                            <div class="item uk-margin">
                                <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input" placeholder="Phone">
                            </div>

                            <div class="item uk-margin">
                                <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
                            </div>
                        </div>

                        <div class="item">
                            <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4"
                                placeholder="Your Message"></textarea>
                        </div>

                        <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Contact Area -->