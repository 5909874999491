<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Contact</h1>
        <!-- <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Contact</li>
        </ul> -->
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Contact Now</span>
            <h2>Talk To us!</h2>
            <strong>You’ve got questions, we’ve got answers.</strong>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="map-img">
                    <img src="../../../../assets/img/contact/worldmap.png" alt="map">
                    <div class="location uk-location1">
                        <a href="#" class="active">
                            <div class="location-info">
                                <h5>Pune</h5>
                                <small>INDIA</small>
                            </div>
                        </a>
                    </div>
                    <div class="location uk-location2">
                        <a href="#">
                            <div class="location-info">
                                <h5>London</h5>
                                <small>UNITED KINGDOM</small>
                            </div>
                        </a>
                    </div>

                    <div class="location singapore-location3">
                        <a href="#">
                            <div class="location-info">
                                <h5>Singapore</h5>
                                <small>SINGAPORE</small>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="item">
                <form id="contactForm" [formGroup]="contactForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="name" id="name" placeholder="Name"
                                formControlName="name">
                            <span *ngIf="contactForm.controls['name'].touched && contactForm.controls['name'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>

                        <div class="item uk-margin">
                            <input type="email" class="uk-input" name="email" id="email" placeholder="Email"
                                formControlName="email">
                            <span *ngIf="contactForm.controls['email'].touched && contactForm.controls['email'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" placeholder="Phone" formControlName="mobile">
                            <span
                                *ngIf="contactForm.controls['mobile'].touched && contactForm.controls['mobile'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject"
                                formControlName="subject">
                            <span
                                *ngIf="contactForm.controls['subject'].touched && contactForm.controls['subject'].invalid"
                                class="text-danger">This field is required*</span>
                        </div>
                    </div>

                    <div class="item">
                        <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4"
                            formControlName="message" placeholder="Your Message"></textarea>
                    </div>

                    <button type="submit" class="uk-button uk-button-default" [disabled]="!contactForm?.valid"
                        (click)="onContact(contactForm)">Submit
                        Message</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->