<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Blog</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Blog</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">26 April</span>
                    <h3><a routerLink="/blog-details">11 Tools to Help You Easily Create Proposals</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">27 April</span>
                    <h3><a routerLink="/blog-details">The Outlook for Digital Agencies in 4 Charts</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">26 April</span>
                    <h3><a routerLink="/blog-details">11 Tools to Help You Easily Create Proposals</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">27 April</span>
                    <h3><a routerLink="/blog-details">The Outlook for Digital Agencies in 4 Charts</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">25 April</span>
                    <h3><a routerLink="/blog-details">The 13 Best Time Tracking Apps of 2023</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">26 April</span>
                    <h3><a routerLink="/blog-details">11 Tools to Help You Easily Create Proposals</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">27 April</span>
                    <h3><a routerLink="/blog-details">The Outlook for Digital Agencies in 4 Charts</a></h3>
                    <a routerLink="/blog-details" class="read-more">Read More</a>
                </div>
            </div>
        </div>

        <div class="pagination-area">
            <ul class="uk-pagination uk-flex-center">
                <li><a href="#"><span class="flaticon-back"></span></a></li>
                <li><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li class="uk-active"><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#"><span class="flaticon-right"></span></a></li>
            </ul>
        </div>
    </div>
</section>
<!-- End Blog Area -->