<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>About</h1>
        <!-- <ul>
            <li><a routerLink="/">Home</a></li>
            <li>About</li>
        </ul> -->
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>WHO WE ARE</span>
                        <h2>The Future Of <span>software development.</span></h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!-- <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div> -->
                        <h3>Thank you for a minute to read about us.</h3>
                        <p>
                            Zest India is a global software services company founded in 2015 and headquartered in Pune,
                            Maharashtra, India. We are ISO 9001:2015 certified and have successfully expanded our
                            business globally, with offices in Singapore and the United Kingdom.
                            <br><br>
                            We offer innovative, up-to-date, and dynamic services to all sizes of enterprises. Our
                            services include mobile application development, web application development, and more.
                            <br><br>
                            We are proud to have built long-standing relationships with many of our clients, some of
                            whom have been with us since the beginning. Zest India is one of the leading website and
                            mobile application development companies that can help your business stand out from the
                            competition. We offer the best development and design services at affordable prices..
                        </p>
                        <div class="signature">
                            <img src="/assets/img/who we are/About us logo.jpeg.jpg" alt="signature" width="25%">
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="/assets/img/who we are/who we are.jpg" class="aboutImg" alt="about-img">
                    <!-- <img src="assets/img/about2.jpg" class="about-img2" alt="about-img"> -->
                    <img src="assets/img/1.png" class="shape-img" alt="shape">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Creative Reviews Area -->
<section id="testimonials" class="feedback-section uk-section">
    <div class="uk-container">
        <div class="uk-grid" uk-grid>
            <div class="uk-width-1-3@m">
                <div class="uk-section-title section-title">
                    <span>Testimonials</span>
                    <h2>What Clients Say About Us</h2>
                    <div class="bar"></div>
                </div>
            </div>

            <div class="uk-width-expand@m">
                <div class="feedback-slides-two owl-carousel owl-theme">
                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            I highly recommend Zest India. They are a very professional and efficient company that was
                            easily able to do everything I required for a mobile application and web interface. The
                            price was fair and they were able to complete everything in the time provided. This was done
                            through daily contact and weekly updates on the project versions. If you are browsing
                            companies, I can definitely say that Zest India is a 5-star company like the reviews say.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/fabtech.jpeg" alt="Zest India IT Services">
                            <h3>Ameet R.</h3>
                            <span>CTO at Fabtech Projects & Engg. Ltd</span>
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            I had a very smooth working experience with Zest India over a 6-month period. They
                            communicated well with me, understood my requirements, and worked on them. They did not
                            overpromise on features and were very transparent about their deliverables. I am very happy
                            with their overall work.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/kommu.jpeg" alt="Zest India IT Services">
                            <h3>Abrams B.</h3>
                            <span>CO-Founder at Kommu</span>
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            Hired for a complex app, met my expectations. Already plan to hire again on future projects.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/lumistella.jpeg" alt="Zest India IT Services">
                            <h3>Armando O.</h3>
                            <span>Digital Design Manager at The Lumistella Company</span>
                        </div>
                    </div>
                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            Our project was rather complicated and difficult, but the team at Zest India managed to
                            handle it with great patience and care. They followed each step of the process and listened
                            to my business requirements. Several issues arose while putting the changes live on my
                            website, and this is when the team showed their professionalism. They managed to resolve all
                            issues one after another with good attention and time management. I would definitely
                            recommend working with Zest India.
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/delux.jpeg" alt="Zest India IT Services">
                            <h3>Yogesh B.</h3>
                            <span>Assistant manager at Delux Bearing</span>
                        </div>
                    </div>
                    <div class="single-feedback-item">
                        <i class="flaticon-quote"></i>
                        <p>
                            Great work! They are excellent communicator and work is great!
                        </p>
                        <div class="client-info">
                            <img src="/assets/img/testimonials/market-dojo.jpeg" alt="Zest India IT Services">
                            <h3>Niholas M.</h3>
                            <span>CO-Founder at Market Dojo</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Reviews Area -->

<!-- Start Partner Area -->
<!-- <div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner Area -->

<!-- Start Team Area -->
<!-- <section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Our Team</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">View All</a>
        </div>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team2.jpg" alt="image">

            <div class="team-content">
                <h3>David Warner</h3>
                <span>UX/UI Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team3.jpg" alt="image">

            <div class="team-content">
                <h3>Emili Lucy</h3>
                <span>Project Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team4.jpg" alt="image">

            <div class="team-content">
                <h3>Steven Smith</h3>
                <span>Marketing Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team5.jpg" alt="image">

            <div class="team-content">
                <h3>Steve Eva</h3>
                <span>Creative Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->

<!-- Vision and Mission area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-image">
                    <img src="assets/img/about/vision.png" alt="image">
                    <img src="assets/img/about/mission.png" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>Why People Choose Us</span>
                        <h2>Empowering Business Success with Top-tier Services</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <!-- <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div> -->
                        <h3>Our Mission</h3>
                        <p>
                            To help businesses thrive in the global market by investing in new technologies and
                            developing innovative solutions.
                        </p>
                        <h3>Our Vision</h3>
                        <p>
                            To be known for providing exceptional, world-class services by combining cutting-edge
                            technology and innovative ideas that drive growth.
                        </p>
                        <h3>Our Strategy</h3>
                        <p>
                            We believe in providing our clients with the highest value of services, which is why we have
                            been consistently awarded and recognized for our excellent work.
                        </p>
                        <div class="signature">
                            <img src="assets/img/about/strategy.png" alt="signature">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>

<!-- End Vision and Mission area -->