import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-service-details",
    templateUrl: "./service-details.component.html",
    styleUrls: ["./service-details.component.scss"],
})
export class ServiceDetailsComponent implements OnInit {
    selecetedService: any;
    serviceForBreadCrumb: any;
    selectedNavItem!: string;
    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.route.params.subscribe((data: any) => {
            this.selecetedService = data?.name;
            this.selectedNavItem = data?.name;
            this.serviceForBreadCrumb = data?.name
                ?.split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        });
    }

    goToDetails(serviceName: any) {
        this.selectedNavItem = serviceName;
        this.router.navigateByUrl(`/service-details/${serviceName}`);
    }
}
