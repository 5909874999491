import { Component, OnInit } from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
} from "@angular/forms";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    contactForm!: FormGroup;
    constructor(private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.contactForm = this.formBuilder.group({
            name: new FormControl("", [Validators.required]),
            email: new FormControl("", [
                Validators.required,
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ]),
            mobile: new FormControl("", [
                Validators.required,
                Validators.maxLength(12),
            ]),
            subject: new FormControl("", [Validators.required]),
            message: new FormControl("", [Validators.required]),
        });
    }

    onContact(data: any) {
        debugger;
        let contactedData = data?.value;
        this.contactForm.reset();
        alert("Thanks for the Response!!");
    }
}
